import { render, staticRenderFns } from "./payment-iframe.vue?vue&type=template&id=5b4b9221&scoped=true"
import script from "./payment-iframe.vue?vue&type=script&lang=js"
export * from "./payment-iframe.vue?vue&type=script&lang=js"
import style0 from "./payment-iframe.vue?vue&type=style&index=0&id=5b4b9221&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4b9221",
  null
  
)

export default component.exports