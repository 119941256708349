<template>
  <v-card elevation="0" class="px-sm-10 flex">
    <div class="text-h5 font-weight-medium pa-3">Store</div>
    <v-tabs
      v-model="tab"
      :fixed-tabs="$vuetify.breakpoint.xs"
      background-color="white"
      color="black"
    >
      <v-tab to="/tourist/store"> {{ $t('current') }} </v-tab>
      <v-tab to="/tourist/store/history"> {{ $t('Purchase history') }} </v-tab>
      <v-tabs-items v-model="tab" class="pt-3">
        <v-tab-item value="/tourist/store">
          <v-row v-if="reservations.length">
            <v-col
              v-for="reservation in reservations"
              :key="reservation.id"
              cols="auto"
              class="pa-5 px-10 px-sm-5"
            >
              <v-card
                elevation="3"
                class="d-flex pointer pa-3 card-hover"
                @click="goToStore(reservation)"
              >
                <v-avatar tile size="100" class="rounded">
                  <img :src="reservation.listing.container_picture" alt="" />
                </v-avatar>
                <div class="flex d-flex px-3 flex-column">
                  <div class="d-flex align-center text-caption secondary--text">
                    <span>
                      {{
                        formatDate(
                          reservation.check_in,
                          'MMM Do',
                          reservation.listing.timezone
                        )
                      }}</span
                    >
                    <span class="mx-3">-</span>
                    <span>
                      {{
                        formatDate(
                          reservation.check_out,
                          'MMM Do',
                          reservation.listing.timezone
                        )
                      }}</span
                    >
                    <v-divider vertical class="mx-2" />
                    <span class="text-caption">{{
                      reservation.listing.city_name
                    }}</span>
                  </div>
                  <div class="text-caption text-sm-subtitle-1">
                    {{ reservation.listing.nickname }}
                  </div>
                  <div class="text-caption font-italic">
                    By
                    {{ reservation.listing.resort_name }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab x-small class="icon-hover">
                    <v-icon x-small> $right_arrow </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!--          <v-row v-else class="align-center flex justify-center">-->
          <!--            <v-card elevation="0" class="pa-5 text-center">-->
          <!--              <v-img src="@/assets/images/draw-vacation.jpg" max-width="600" />-->
          <!--              <div class="text-subtitle-1 font-weight-medium ma-5">-->
          <!--                Don't waste your time and start exploring-->
          <!--                {{ companyName }} vacation-->
          <!--              </div>-->
          <!--              <v-btn-->
          <!--                link-->
          <!--                href="https://designedvr.com"-->
          <!--                target="_blank"-->
          <!--                color="primary"-->
          <!--                class="btn-nudge pa-3"-->
          <!--              >-->
          <!--                Explore {{ companyName }}-->
          <!--              </v-btn>-->
          <!--            </v-card>-->
          <!--          </v-row>-->
        </v-tab-item>
        <v-tab-item value="/tourist/store/history">
          <router-view />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import CommonFunctions from '@/components/mixins/common-functions'

export default {
  name: 'HomePage',
  mixins: [CommonFunctions],
  data() {
    return {
      tab: 'current',
    }
  },
  computed: {
    reservationsFetched() {
      return this.$store.state.tourist.reservationsFetched
    },
    reservations() {
      return this.$store.getters.paidReservations
    },
  },
  methods: {
    goToStore(reservation) {
      this.$router.push({
        name: 'tourist-store',
        params: { id: reservation.confirmation_code },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-nudge {
  -webkit-animation: nudge 5s infinite linear;
  animation: nudge 5s infinite linear;
}
.card-hover:hover {
  .icon-hover {
    color: var(--v-primary-base);
  }
  .icon-hover:hover {
    color: var(--v-primary-base);
  }
}
@-webkit-keyframes nudge {
  0%,
  90% {
    -webkit-transform: rotate(0deg);
  }
  92% {
    -webkit-transform: rotate(4deg);
  }
  94% {
    -webkit-transform: rotate(-4deg);
  }
  96% {
    -webkit-transform: rotate(4deg);
  }
  98% {
    -webkit-transform: rotate(-4deg);
  }
  100% {
    -webkit-transform: rotate(4deg);
  }
}
@keyframes nudge {
  0%,
  90% {
    transform: rotate(0deg);
  }
  92% {
    transform: rotate(4deg);
  }
  94% {
    transform: rotate(-4deg);
  }
  96% {
    transform: rotate(4deg);
  }
  98% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(4deg);
  }
}
</style>
