<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex dvr-datetime text-caption flex-column pa-2"
          v-on="on"
        >
          <div class="flex text-caption text-uppercase">{{ $t('Guests') }}</div>
          <div class="flex text-body-2 font-weight-medium">
            {{ totalGuests }} {{ $t('Guests') }}
          </div>
        </div>
      </template>
      <v-card v-if="!readonly" min-width="230" width="400" elevation="1">
        <div class="pa-3">
          <div v-for="(guestType, index) in guestTypes" :key="index">
            <div class="d-flex py-1">
              <AddRemoveItem
                :label="$t(guestType.label)"
                :value.sync="guests[guestType.key]"
                :description="$t(guestType.description)"
              />
            </div>
            <v-divider v-if="index < guestTypes.length - 1" class="my-2" />
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import AddRemoveItem from '@/components/common/AddRemoveItem'
import sum from 'lodash/fp/sum'

export default {
  name: 'GuestsSelect',
  components: { AddRemoveItem },
  props: {
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      guests: this.value,
      guestTypes: [
        {
          key: 'adults',
          label: this.$t('Adults'),
          description: this.$t('From 13 years'),
        },
        {
          key: 'kids',
          label: this.$t('Kids'),
          description: this.$t('3-12 years'),
        },
        {
          key: 'babies',
          label: this.$t('Babies'),
          description: this.$t('Up to 3 years'),
        },
      ],
    }
  },
  computed: {
    totalGuests() {
      return sum(Object.values(this.guests))
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.guests = newValue
      },
      deep: true,
    },
    totalGuests() {
      this.$emit('update:value', this.guests)
    },
  },
}
</script>

<style lang="scss" scoped>
.dvr-datetime {
  height: 60px;
  border-radius: 8px;
  width: 200px;
  border: 1px solid #d5d5d5;
}
</style>
