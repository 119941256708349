<template>
  <v-card
    class="d-flex flex-column flex-sm-row rounded"
    :elevation="0"
    @click="showInfo ? goToReservation : undefined"
  >
    <v-img
      :src="reservation.listing.container_picture"
      :class="[{ 'mb-3': isMobile }, 'rounded']"
      :max-width="isMobile ? '100%' : '100'"
      :aspect-ratio="isMobile ? '3' : ''"
      :alt="`${reservation.listing.nickname} image`"
    />
    <div class="d-flex px-3 flex-column justify-space-between">
      <div>
        <div
          class="d-flex align-center text-uppercase text-caption secondary--text"
        >
          <span>{{ formatReservationPeriod(reservation, 'MMM Do') }}</span>
          <span class="font-weight-thin mx-1 mx-md-4">|</span>
          <span>{{ reservation.listing.city_name }}</span>
        </div>
        <div class="text-caption text-sm-subtitle-1">
          {{ reservation.listing.nickname }}
        </div>
        <div class="text-caption font-italic">
          By
          {{ reservation.listing.resort_name }}
        </div>
      </div>
      <router-link
        v-if="showInfo"
        class="black--text font-weight-medium text-caption"
        :to="{
          name: 'reservation-info',
          params: { id: reservation.confirmation_code },
        }"
      >
        {{ $t('Show details') }}
      </router-link>
    </div>
  </v-card>
</template>

<script>
import commonFunctions from 'components/mixins/common-functions'
import deviceMixin from 'components/mixins/deviceMixin'
export default {
  name: 'ReservationCard',
  mixins: [commonFunctions, deviceMixin],
  props: {
    reservation: {
      type: Object,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToReservation() {
      this.$router.push({
        name: 'reservation-info',
        params: { id: this.reservation.confirmation_code },
      })
    },
  },
}
</script>

<style scoped></style>
