import { render, staticRenderFns } from "./guests-select.vue?vue&type=template&id=f96b5f32&scoped=true"
import script from "./guests-select.vue?vue&type=script&lang=js"
export * from "./guests-select.vue?vue&type=script&lang=js"
import style0 from "./guests-select.vue?vue&type=style&index=0&id=f96b5f32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f96b5f32",
  null
  
)

export default component.exports